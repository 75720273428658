/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import getUuid from 'utils/others/get-uuid'
import type { PayloadAction, Middleware } from '@reduxjs/toolkit'
import type { AlertProps } from 'components/containers/alert/alert.component'
import type { LinkProps } from 'react-router-dom'

/**
 * Navigation middleware, must have "getDefaultMiddleware"
 */
export const navigationMiddleware: Array<Middleware> = []

/** Alert Type used to display information in app */
export interface AlertType {
    /** Key */
    key?: string
    /** Content */
    content?: AlertProps['children']
    /** Type */
    type?: AlertProps['type']
    /** Timeout in ms */
    timeout?: number
    /** Should the the alert be persisted ONCE across navigation. Default to `false` */
    persist?: boolean
}

export interface NavigationState {
    /** Current page title */
    pageTitle: Array<string>
    /** Page to go back */
    backHistory: LinkProps['to']
    /** Alert */
    alert: AlertType | null
}

/**
 * Navigation Slice
 */
const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        pageTitle: [],
        backHistory: {},
        alert: null,
    } as NavigationState,
    reducers: {
        /**
         * SetNavigation
         * @param state state
         * @param action action
         */
        setNavigation: (
            state,
            action: PayloadAction<{
                /** Title */
                title: Array<string> | string
                /** BackTo */
                backTo: LinkProps['to']
            }>,
        ) => {
            const pageTitle = Array.isArray(action.payload.title) ? action.payload.title : [action.payload.title]

            // Check if different items
            if (pageTitle.length !== state.pageTitle.length || !pageTitle.every((element, index) => element === state.pageTitle[index])) {
                state.pageTitle = pageTitle
            }

            if (state.backHistory !== action.payload.backTo) {
                state.backHistory = action.payload.backTo
            }
        },
        /**
         * SetAlert
         * @param state state
         * @param action action
         */
        setAlert: (state, action: PayloadAction<AlertType | null>) => {
            state.alert = action.payload

            // Assign a uuid to the key if there's an alert but no key was sent
            if (state.alert && !state.alert.key) {
                state.alert.key = getUuid()
            }
        },
    },
})

export const { setNavigation, setAlert } = navigationSlice.actions
export const navigationReducer = navigationSlice.reducer
