const HALF_RIGHT_ANGLE = 45

/**
 * Get direction by angle in degree
 * @param start start
 * @param end end
 */
function getAngleDirection(
    { pageX: startPageX, pageY: startPageY }: Pick<Touch, 'pageX' | 'pageY'>,
    { pageX: endPageX, pageY: endPageY }: Pick<Touch, 'pageX' | 'pageY'>,
): {
    /** Degree */
    degree: number
    /** Direction */
    direction: 'horizontal' | 'vertical'
} {
    const radian = Math.atan(Math.abs(startPageY - endPageY) / Math.abs(startPageX - endPageX))

    /** Degree between 0 and 90 */
    const degree = radian * (180 / Math.PI)

    return {
        degree,
        direction: degree < HALF_RIGHT_ANGLE ? 'horizontal' : 'vertical',
    }
}

/**
 * Get direction of a touch event.
 * @param start start
 * @param end end
 * @returns Direction
 */
export default function getTouchDirection(
    { pageX: startPageX, pageY: startPageY }: Pick<Touch, 'pageX' | 'pageY'>,
    { pageX: endPageX, pageY: endPageY }: Pick<Touch, 'pageX' | 'pageY'>,
): {
    /** Direction */
    direction: 'left' | 'right' | 'up' | 'down'
    /** Angle */
    angle: ReturnType<typeof getAngleDirection>
} {
    const horizontalDiff = endPageX - startPageX
    const verticalDiff = endPageY - startPageY

    const { degree, direction } = getAngleDirection({ pageX: startPageX, pageY: startPageY }, { pageX: endPageX, pageY: endPageY })

    // Move is horizontal
    if (direction === 'horizontal') {
        return {
            angle: {
                degree,
                direction,
            },
            direction: horizontalDiff >= 0 ? 'right' : 'left',
        }
    }

    // Move is vertical
    return {
        angle: {
            degree,
            direction,
        },
        direction: verticalDiff >= 0 ? 'down' : 'up',
    }
}
