import { useCallback, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { isCancelledError, useMutation, useQueryClient } from '@tanstack/react-query'
import { useMount, usePrevious, useUnmount } from 'react-use'
import { IDLE, PENDING, REJECTED, RESOLVED } from 'types/status'
import useDispatches from 'store/dispatches.hook'
import { useBroadcastChannel, useNavigate, useStateToRef, useWakeLock } from 'hooks'
import getFilteredItems from 'pages/searches/[searchid]/index/functions/get-filtered-items.function'
import { BROADCAST_CHANNEL_KEY, BROADCAST_FETCH_FAILED } from 'types/others'
import { getItemFilters } from 'pages/searches/[searchid]/index/hooks/item-filters.hook'
import { itemsQueries } from 'requests/queries'
import type { SearchidSearchesContextType } from 'pages/searches/[searchid]/index/index.context'
import type { Status } from 'types/status'
import type { ScraperValues } from 'types/consts/scraper.const'
import type { ScraperAll } from 'types/types'
import type { MetaQuery } from 'requests/types/common'
import type { ItemType, SearchType } from 'requests/types/helpers'

export interface UseIndexItemsParams
    extends Pick<SearchidSearchesContextType, 'indexVisible' | 'searchUser' | 'scraperValuesEnable' | 'currentScraperIndex'> {
    /** SearchId */
    searchId: SearchType['_id']
}

export interface UseIndexItemsReturns {
    /** FetchItems */
    fetchItems: (index?: number) => void
    /** Rollback */
    rollback: (index?: number) => void
    /** UpdateOneItem */
    updateOneItem: (item: ItemType, scraperValue: ScraperValues) => void
    /** Status */
    tabsStatus: TabsStatus
    /** Items */
    tabsItems: TabsItems
    /** ClearOnSwipeEnd */
    clearOnSwipeEnd: (prevIndex: number, newIndex: number) => void
    /** Cancel all request */
    cancelAllRunningRequests: () => void
}

export interface TabsItems {
    [key: string]: Array<ItemType>
}

interface TabsStatus {
    [key: string]: Status
}

/**
 * Check if all items array are empty on a `TabsItems`
 * @param tabsItems TabsItems
 */
const isItemsEmpty = (tabsItems: TabsItems) => Object.keys(tabsItems).every(key => tabsItems[key]?.length === 0)
/**
 * Check if all status are `PENDING` on a `tabsStatus`
 * @param tabsStatus TabsStatus
 */
const isStatusPending = (tabsStatus: TabsStatus) => Object.keys(tabsStatus).every(key => tabsStatus[key] === PENDING)

/**
 * UseIndexItems to handle items: api call, filters, etc.
 */
export default function useIndexItems({
    currentScraperIndex,
    scraperValuesEnable,
    searchUser,
    searchId,
    indexVisible,
}: UseIndexItemsParams): UseIndexItemsReturns {
    const {
        search: { setItemsFound, setLastSearchOn },
        navigation: { setAlert },
    } = useDispatches()
    const { allowWakeLock, disableWakeLock } = useWakeLock()
    const location = useLocation()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const defaultItems = useMemo(
        () =>
            new Array(scraperValuesEnable.length)
                .fill({})
                .map((_, i) => i)
                .reduce((a, b) => ({ ...a, [b]: [] }), { [-1]: [] }),
        [scraperValuesEnable.length],
    ) as TabsItems
    const defaultStatus = useMemo(
        () =>
            new Array(scraperValuesEnable.length)
                .fill({})
                .map((_, i) => i)
                .reduce((a, b) => ({ ...a, [b]: PENDING }), { [-1]: PENDING as Status }),
        [scraperValuesEnable.length],
    ) as TabsStatus

    /** Tabs content */
    const [tabsIniItems, setTabsIniItems] = useState<TabsItems>(defaultItems)
    const [tabsIniStatus, setTabsIniStatus] = useState<TabsStatus>(defaultStatus)

    /** Index visible in a ref to access true current value at any moment */
    const indexVisibleRef = useStateToRef(indexVisible)

    /** Store previous last search on Date, in case we need to rollback to it */
    const prevLastSearch = useRef<{
        /** Last search on Date */
        on: Date | null
        /** Last search query */
        query: string | null
    }>({
        on: null,
        query: null,
    })
    /** Store previous last search failed on Date. Sent from SW */
    const prevLastSearchFailedOn = useRef<Date | null>(null)
    /** Keep in memory the previous items to keep filtered items on previous scraper, even if filters are cleared */
    const prevTabsItems = useRef<TabsItems>(tabsIniItems)

    /** True tabs items to be displayed */
    const tabsItems = useMemo<TabsItems>(() => {
        /** Items filter filtered */
        const tabsItemsFiltered = {
            ...prevTabsItems.current,
            [currentScraperIndex]: [PENDING, IDLE].includes(tabsIniStatus[currentScraperIndex] || IDLE)
                ? []
                : getFilteredItems({
                      items: tabsIniItems[currentScraperIndex]!,
                      filters: getItemFilters(),
                  }),
        } as TabsItems

        // Update number of items found
        if (![PENDING, IDLE].includes(tabsIniStatus[currentScraperIndex] || IDLE) && currentScraperIndex === indexVisible) {
            setTimeout(() => {
                setItemsFound({
                    total: tabsIniItems[currentScraperIndex]?.length ?? null,
                    current: tabsItemsFiltered[currentScraperIndex]?.length ?? null,
                })
            }, 0)

            // Update search query at this point to be able to rollback if needed
            prevLastSearch.current.query = location.search
        }

        // Update previous value
        prevTabsItems.current = tabsItemsFiltered

        return tabsItemsFiltered
    }, [currentScraperIndex, tabsIniStatus, tabsIniItems, indexVisible, location.search, setItemsFound])

    /** Override value to force all indexes to have "PENDING" if not the current visible */
    const tabsStatus = useMemo(
        () =>
            Object.keys(tabsIniStatus).reduce<TabsStatus>(
                (prev, curr) => ({ ...prev, [curr]: indexVisible !== +curr ? PENDING : tabsIniStatus[curr]! }),
                {},
            ),
        [indexVisible, tabsIniStatus],
    )

    const { mutate: updateHistory } = useMutation({
        ...itemsQueries.updateHistory(),
        meta: {
            isGlobalErrorEnable: false,
        } as MetaQuery,
    })

    /** Cancel all request */
    const cancelAllRunningRequests = useCallback(() => {
        scraperValuesEnable.forEach(scraperValue =>
            queryClient.cancelQueries({
                queryKey: ['searches', searchId, 'scrapers', scraperValue, 'items'],
                exact: true,
            }),
        )
    }, [queryClient, scraperValuesEnable, searchId])

    /**
     * Cleanup stuff after a fetch is done
     */
    const cleanupAfterSwipe = useCallback(
        ({
            index,
            status,
            items = [],
            lastSearchOn = null,
        }: {
            /** ScraperIndex */
            index: number
            /** Status */
            status: Status
            /** Items */
            items?: Array<ItemType>
            /** LastSearchOn */
            lastSearchOn?: Date | null
        }) => {
            // Set the new date
            prevLastSearch.current.on = lastSearchOn
            setLastSearchOn(lastSearchOn)
            setItemsFound({
                total: status === REJECTED ? null : items.length,
                current: status === REJECTED ? null : getFilteredItems({ items, filters: getItemFilters() }).length,
            })
            disableWakeLock()

            if (status !== REJECTED && (searchUser?.[scraperValuesEnable[index]!] as ScraperAll)?.histories?.isEnable) {
                updateHistory({
                    params: {
                        path: {
                            searchId: searchId!,
                            scraperValue: scraperValuesEnable[index]!,
                        },
                    },
                    body: {
                        histories: {
                            elements: items.map(item => ({ url: item.url!, price: item.price?.base?.original || null })),
                        },
                    },
                })
            }
        },
        [disableWakeLock, updateHistory, scraperValuesEnable, searchId, searchUser, setItemsFound, setLastSearchOn],
    )

    /** Rollback to previous search results */
    const rollback = useCallback(
        (index = currentScraperIndex) => {
            cancelAllRunningRequests()
            navigate(
                { search: prevLastSearch.current.query || '', hash: scraperValuesEnable[index] },
                { replace: true, preventScrollReset: true },
            )
            setLastSearchOn(prevLastSearch.current.on)
            setItemsFound({
                total: tabsIniItems[index]!.length,
                current: getFilteredItems({ items: tabsIniItems[index]!, filters: getItemFilters() }).length,
            })
            disableWakeLock()
        },
        [
            cancelAllRunningRequests,
            currentScraperIndex,
            disableWakeLock,
            navigate,
            scraperValuesEnable,
            setItemsFound,
            setLastSearchOn,
            tabsIniItems,
        ],
    )

    /** Fetch items */
    const fetchItems = useCallback(
        async (index = currentScraperIndex) => {
            cancelAllRunningRequests()

            setLastSearchOn(null)
            setItemsFound({ total: null, current: null })
            setAlert(null)
            setTabsIniStatus(prevTabsIniStatus => {
                if (prevTabsIniStatus[index] === PENDING) {
                    return prevTabsIniStatus
                }
                return { ...prevTabsIniStatus, [index]: PENDING }
            })
            allowWakeLock()
            prevLastSearchFailedOn.current = null

            try {
                const { items: data } = await queryClient.fetchQuery({
                    ...itemsQueries.getAll({ searchId, scraperValue: scraperValuesEnable[index]! }),
                })

                setTabsIniItems(prevTabsIniItems => ({ ...prevTabsIniItems, [index]: data }))
                setTabsIniStatus(prevTabsIniStatus => ({ ...prevTabsIniStatus, [index]: RESOLVED }))

                // If at this point the index visible is the same as the fetched index, clean up
                if (indexVisibleRef.current === index) {
                    cleanupAfterSwipe({
                        status: RESOLVED,
                        items: data,
                        lastSearchOn: prevLastSearchFailedOn.current || new Date(),
                        index,
                    })
                }
            } catch (error) {
                if (isCancelledError(error)) {
                    return
                }
                setTabsIniItems(prevTabsIniItems => {
                    // Do not update if same items
                    if (prevTabsIniItems[index]?.length !== 0) {
                        return { ...prevTabsIniItems, [index]: [] }
                    }
                    return prevTabsIniItems
                })
                setTabsIniStatus(prevTabsIniStatus => {
                    // Do not update if same status
                    if (prevTabsIniStatus[index] !== REJECTED) {
                        return { ...prevTabsIniStatus, [index]: REJECTED }
                    }
                    return prevTabsIniStatus
                })

                // If at this point the index visible is the same as the fetched index, clean up
                if (indexVisibleRef.current === index) {
                    cleanupAfterSwipe({ status: REJECTED, index, lastSearchOn: new Date() })
                }
            }
        },
        [
            currentScraperIndex,
            cancelAllRunningRequests,
            setLastSearchOn,
            setItemsFound,
            setAlert,
            allowWakeLock,
            queryClient,
            searchId,
            scraperValuesEnable,
            indexVisibleRef,
            cleanupAfterSwipe,
        ],
    )

    /** Update on item */
    const updateOneItem = useCallback<UseIndexItemsReturns['updateOneItem']>(
        (item, scraperValue) => {
            setTabsIniItems((prevItems: TabsItems): TabsItems => {
                const prevItemsCopy = structuredClone(prevItems)
                const scraperIndex = scraperValuesEnable?.findIndex(x => x === scraperValue) ?? -1

                if (scraperIndex < 0) {
                    return prevItems
                }

                const itemIndex = prevItemsCopy[scraperIndex]!.findIndex(x => x.url === item.url)

                if (itemIndex < 0) {
                    return prevItems
                }

                prevItemsCopy[scraperIndex]![itemIndex] = item

                return prevItemsCopy
            })
        },
        [scraperValuesEnable],
    )

    // Clear items on swipe end
    const clearOnSwipeEnd = useCallback<UseIndexItemsReturns['clearOnSwipeEnd']>(
        (prevIndex, newIndex) => {
            setTabsIniItems(prevTabsIniItems => {
                // We do not update if already all others tabs are empty: it means we are still on same scraper
                if (isItemsEmpty(prevTabsIniItems)) {
                    return prevTabsIniItems
                }

                const newValue: TabsItems = { ...defaultItems, [newIndex]: prevTabsIniItems[newIndex] }

                // Reset previous value
                prevTabsItems.current = newValue

                return newValue
            })
            setTabsIniStatus(prevTabsIniStatus => {
                // We do not update if already all others tabs are pending
                if (isStatusPending(prevTabsIniStatus)) {
                    return prevTabsIniStatus
                }
                return { ...defaultStatus, [newIndex]: prevTabsIniStatus[newIndex] }
            })

            // If status is currently RESOLVED/REJECTED AND it's a new index, clean up
            if ([RESOLVED, REJECTED].includes(tabsIniStatus[newIndex]!) && newIndex !== prevIndex) {
                cleanupAfterSwipe({
                    status: tabsIniStatus[newIndex]!,
                    items: tabsIniItems[newIndex],
                    lastSearchOn: prevLastSearchFailedOn.current || new Date(),
                    index: newIndex,
                })
            }
        },
        [cleanupAfterSwipe, defaultItems, defaultStatus, tabsIniItems, tabsIniStatus],
    )

    // Subscribe to Broadcast Channel to check if Fetch failed
    useBroadcastChannel({
        name: BROADCAST_CHANNEL_KEY,
        onMessage: useCallback(ev => {
            if (ev.data.type === BROADCAST_FETCH_FAILED) {
                prevLastSearchFailedOn.current = ev.data.payload.date
            }
        }, []),
    })

    useMount(() => {
        if (currentScraperIndex > -1) {
            fetchItems()
        }
    })

    // Clear on unmount
    useUnmount(() => {
        setLastSearchOn(null)
        setItemsFound({ total: null, current: null })
        disableWakeLock()
        cancelAllRunningRequests()
    })

    const prevSearchId = usePrevious(searchId)

    // Case to reset items/status keys
    if (
        // If searchId change and objects are not already clear
        (prevSearchId && searchId !== prevSearchId && (!isItemsEmpty(tabsIniItems) || !isStatusPending(tabsIniStatus))) ||
        // Or if not same number of items keys enable (allow to check if switching areDisableScraperVisible)
        Object.keys(defaultItems).length !== Object.keys(tabsIniItems).length ||
        // Or if not same number of status keys enable (allow to check if switching areDisableScraperVisible)
        Object.keys(defaultStatus).length !== Object.keys(tabsIniStatus).length
    ) {
        prevTabsItems.current = defaultItems
        setTabsIniItems(defaultItems)
        setTabsIniStatus(defaultStatus)
    }

    return {
        fetchItems,
        rollback,
        updateOneItem,
        tabsStatus,
        tabsItems,
        clearOnSwipeEnd,
        cancelAllRunningRequests,
    }
}
