import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'
import { SEARCH_TEXT, SEARCH_IS_NEW, SEARCH_IS_NEW_PRICE, SEARCH_IS_FAVORITE } from 'types/others'
import { useNavigate } from 'hooks'
import { useCallback } from 'react'

interface SearchParam {
    /** Name */
    name: typeof SEARCH_TEXT | typeof SEARCH_IS_NEW | typeof SEARCH_IS_NEW_PRICE | typeof SEARCH_IS_FAVORITE
    /** Value */
    value?: unknown
}

export interface UseItemFiltersReturns {
    /** Filters */
    filters: {
        /** Text */
        [SEARCH_TEXT]: string
        /** IsNew */
        [SEARCH_IS_NEW]: boolean
        /** IsNewPrice */
        [SEARCH_IS_NEW_PRICE]: boolean
        /** IsFavorite */
        [SEARCH_IS_FAVORITE]: boolean
    }
    /** SetSearchParams */
    setSearchParams: (params: Array<SearchParam>) => void
}

const itemFilterTextSchema = z
    .string()
    .nullable()
    .transform(x => x ?? '')
const itemFilterIsNewSchema = z
    .enum(['true', 'false'])
    .nullable()
    .transform(value => value === 'true')
const itemFilterIsNewPriceSchema = z
    .enum(['true', 'false'])
    .nullable()
    .transform(value => value === 'true')
const itemFilterIsFavoriteSchema = z
    .enum(['true', 'false'])
    .nullable()
    .transform(value => value === 'true')

/**
 * Get Item filtered
 * @param [urlSearchParams=new URLSearchParams(window.location.search)] Search Params
 */
export function getItemFilters(urlSearchParams = new URLSearchParams(window.location.search)) {
    return {
        text: itemFilterTextSchema.safeParse(urlSearchParams.get(SEARCH_TEXT)).data ?? '',
        isNew: itemFilterIsNewSchema.safeParse(urlSearchParams.get(SEARCH_IS_NEW)).data ?? false,
        isNewPrice: itemFilterIsNewPriceSchema.safeParse(urlSearchParams.get(SEARCH_IS_NEW_PRICE)).data ?? false,
        isFavorite: itemFilterIsFavoriteSchema.safeParse(urlSearchParams.get(SEARCH_IS_FAVORITE)).data ?? false,
    }
}

/**
 * UseItemFilters
 */
export default function useItemFilters(): UseItemFiltersReturns {
    const [searchParamsRouter] = useSearchParams()
    const navigate = useNavigate()

    const setSearchParams = useCallback(
        (params: Array<SearchParam>) => {
            const newSearchParams = new URLSearchParams(searchParamsRouter)
            // eslint-disable-next-line no-restricted-syntax
            for (const param of params) {
                if (param.value) {
                    newSearchParams.set(param.name, param.value.toString())
                } else {
                    newSearchParams.delete(param.name)
                }
            }
            navigate({ hash: window.location.hash, search: newSearchParams.toString() }, { preventScrollReset: true, replace: true })
        },
        [navigate, searchParamsRouter],
    )

    return {
        filters: getItemFilters(searchParamsRouter),
        setSearchParams,
    }
}
