const ItemCondition = {
    M: 'Mint',
    NM: 'Near Mint',
    VG: 'Very Good',
    G: 'Good',
    P: 'Poor',
    'N/A': 'N/A',
} as const

export type ItemConditionType = typeof ItemCondition
export type ItemConditionKeys = keyof ItemConditionType
/** @public */
export type ItemConditionValues = ItemConditionType[ItemConditionKeys]

export default ItemCondition
