import { configureStore } from '@reduxjs/toolkit'
import { useDispatch as useDispatchRedux, useSelector as useSelectorRedux } from 'react-redux'
import { navigationMiddleware, navigationReducer } from 'store/slices/navigation.slice'
import { userMiddleware, userReducer } from 'store/slices/user.slice'
import { pwaMiddleware, pwaReducer } from 'store/slices/pwa.slice'
import { homeMiddleware, homeReducer } from 'store/slices/home.slice'
import { searchMiddleware, searchReducer } from 'store/slices/search.slice'
import type { TypedUseSelectorHook } from 'react-redux'

/**
 * @see https://www.valentinog.com/blog/redux/#modern-redux-with-redux-toolkit
 */
const store = configureStore({
    reducer: {
        navigation: navigationReducer,
        home: homeReducer,
        search: searchReducer,
        user: userReducer,
        pwa: pwaReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            ...homeMiddleware,
            ...searchMiddleware,
            ...navigationMiddleware,
            ...userMiddleware,
            ...pwaMiddleware,
        ),
})

export interface StoreState extends ReturnType<typeof store.getState> {}

export type StoreDispatch = typeof store.dispatch

export const useDispatch = useDispatchRedux<StoreDispatch>

export const useSelector: TypedUseSelectorHook<StoreState> = useSelectorRedux

export default store
