import colors from 'styles/exports/colors.module.scss'

/**
 * Get color from SCSS
 * @public Not used atm
 * @param key Color key
 * @returns Hexadecimal color
 */
export default function getColor(
    key:
        | 'white-darkest'
        | 'white-dark'
        | 'white'
        | 'grey-dark'
        | 'grey'
        | 'grey-light'
        | 'grey-lightest'
        | 'pink'
        | 'pink-light'
        | 'danger-dark'
        | 'danger'
        | 'danger-light'
        | 'danger-lightest'
        | 'success'
        | 'success-lightest'
        | 'info'
        | 'info-lightest'
        | 'link'
        | 'gold',
): string {
    return colors[key]
}
