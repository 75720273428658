/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { checkStandalone } from 'hooks/pwa.hook'
import type { PwaInfosType } from 'hooks/pwa.hook'
import type { PayloadAction, Middleware } from '@reduxjs/toolkit'

/**
 * Pwa middleware, must have "getDefaultMiddleware"
 */
export const pwaMiddleware: Array<Middleware> = []

export interface PwaState extends PwaInfosType {
    /** Is app updating */
    isUpdating: boolean
}

/**
 * Pwa Slice
 */
const pwaSlice = createSlice({
    name: 'pwa',
    initialState: {
        isInstallPromptSupported: false,
        promptInstall: () => Promise.resolve(false),
        isStandalone: checkStandalone(),
        isUpdating: false,
    } as PwaState,
    reducers: {
        /**
         * Set pwa
         * @param state state
         * @param action action
         */
        setInformations: (state, action: PayloadAction<PwaInfosType>) => {
            state.isInstallPromptSupported = action.payload.isInstallPromptSupported
            state.promptInstall = action.payload.promptInstall
            state.isStandalone = action.payload.isStandalone
        },
        /**
         * Set pwa
         * @param state state
         * @param action action
         */
        setIsUpdating: (state, action: PayloadAction<boolean>) => {
            state.isUpdating = action.payload
        },
    },
})

export const { setInformations, setIsUpdating } = pwaSlice.actions
export const pwaReducer = pwaSlice.reducer
