/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { QUERY_KEY_REDIRECT, STORAGE_UPDATED_AT_KEY, STORAGE_USER_KEY } from 'types/others'
import { unsubscribe } from 'utils/others/notifications'
import history from 'utils/others/history'
import { isResolved } from 'utils/others/promise-status'
import client from 'requests/client'
import type { ScraperAll } from 'types/types'
import type { PayloadAction, Middleware } from '@reduxjs/toolkit'
import type { ScraperValues } from 'types/consts/scraper.const'
import type { FavoritesType, SearchType, UserType } from 'requests/types/helpers'

/**
 * User middleware, must have "getDefaultMiddleware"
 */
export const userMiddleware: Array<Middleware> = []

export interface UserStateType {
    /** Current user */
    meUser: UserType | null
}

/**
 * User Slice
 */
const userSlice = createSlice({
    name: 'user',
    initialState: {
        meUser: (() => {
            try {
                return JSON.parse(localStorage.getItem(STORAGE_USER_KEY)!)
            } catch (e) {
                return null
            }
        })(),
    } as UserStateType,
    reducers: {
        /**
         * Set me raw user.
         * Updating the user data with the raw API data allow to store raw data in storage an prevent removing stuff in instantiation.
         * @param state state
         * @param action action
         */
        setMeRawUser: (state, action: PayloadAction<UserType>) => {
            state.meUser = action.payload
            localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(state.meUser))
        },
        /**
         * Update Me User Settings
         * @param state state
         * @param action action
         */
        updateMeUserSettings: (state, action: PayloadAction<Pick<UserType, 'username' | 'email' | 'currency' | 'country'>>) => {
            if (!state.meUser) {
                return
            }
            state.meUser.username = action.payload.username
            state.meUser.email = action.payload.email
            state.meUser.currency = action.payload.currency
            state.meUser.country = action.payload.country
            localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(state.meUser))
        },
        /**
         * Update user info
         * @param state state
         * @param action action
         */
        updateMeUser: (
            state,
            action: PayloadAction<{
                /** Data */
                data: SearchType | Array<SearchType>
                /** Action */
                action?: 'upsert' | 'remove'
            }>,
        ) => {
            const { data, action: mode } = action.payload

            const userData = (() => {
                if (Array.isArray(data)) {
                    const newUserData = state.meUser!
                    newUserData.searches = data
                    return newUserData
                }
                const newUserData = state.meUser!
                const searchIndex = newUserData.searches?.findIndex(search => search._id === data._id) ?? -1

                switch (mode || 'upsert') {
                    case 'upsert':
                        if (searchIndex > -1) {
                            newUserData.searches[searchIndex] = data
                        } else {
                            newUserData.searches.push(data)
                        }
                        break
                    case 'remove':
                        newUserData.searches.splice(searchIndex, 1)
                        break
                    default:
                        throw new Error('Invalid me update')
                }

                return newUserData
            })()

            // If user is different, update it (prevent circle dep)
            if (JSON.stringify(userData) !== JSON.stringify(state.meUser)) {
                state.meUser = userData
                localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(state.meUser))
            }
        },
        /**
         * SetItemsFound
         * @param state state
         * @param action action
         */
        updateFavorites: (
            state,
            action: PayloadAction<{
                /** Url */
                favorites: FavoritesType
                /** SearchId */
                searchId: SearchType['_id']
                /** ScraperValue */
                scraperValue: ScraperValues
            }>,
        ) => {
            const { favorites, searchId, scraperValue } = action.payload

            const userData = state.meUser!
            const searchIndex = userData.searches?.findIndex(search => search._id === searchId)

            if (searchIndex === -1) {
                return
            }

            const newUrl = favorites?.elements?.[0]?.url

            if (!newUrl) {
                return
            }

            const scraper = userData.searches[searchIndex]?.[scraperValue] as ScraperAll | undefined

            const urlIndexFavorites = scraper?.favorites?.elements?.findIndex(element => element.url === newUrl) ?? -1

            // If not found, add it
            if (urlIndexFavorites === -1) {
                scraper?.favorites?.elements?.push({ url: newUrl })

                const urlIndexExcludes = scraper?.excludes?.elements?.findIndex(element => element.url === newUrl) ?? -1
                if (urlIndexExcludes > -1) {
                    scraper?.excludes?.elements?.splice(urlIndexExcludes, 1)
                }
            } else {
                // Otherwise remove it
                scraper?.favorites?.elements?.splice(urlIndexFavorites, 1)

                const urlIndexExcludes = scraper?.excludes?.elements?.findIndex(element => element.url === newUrl)
                if (urlIndexExcludes === -1) {
                    scraper?.excludes?.elements?.push({ url: newUrl })
                }
            }

            // Sort favorites elements
            if (scraper?.favorites?.elements) {
                scraper.favorites.elements = scraper.favorites.elements.sort((a, b) => (a.url || '').localeCompare(b.url || ''))
            }

            // Sort excludes elements
            if (scraper?.excludes?.elements) {
                scraper.excludes.elements = scraper.excludes.elements.sort((a, b) => (a.url || '').localeCompare(b.url || ''))
            }

            // If user is different, update it (prevent circle dep)
            if (JSON.stringify(userData) !== JSON.stringify(state.meUser)) {
                state.meUser = userData
                localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(state.meUser))
            }
        },
        /**
         * Sign out
         * @param state state
         * @param action Is with logout in API. If `false`, mean that all tokens are expired so we can't call API to clean some stuff
         */
        signOut: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                ;(async () => {
                    // Get a new JWT in case the one is storage is already expire, so we can Unsubscribe just then
                    await client.PATCH('/api/users/authentication/refresh')

                    // `navigator.serviceWorker.ready` will be forever Pending if there's no SW. So we check promise status
                    if (navigator.serviceWorker && (await isResolved(navigator.serviceWorker.ready))) {
                        await unsubscribe({})
                    }
                    await client.POST('/api/users/authentication/logout')
                })()
            }

            localStorage.removeItem(STORAGE_USER_KEY)
            localStorage.removeItem(STORAGE_UPDATED_AT_KEY)

            state.meUser = null

            const redirectUrl = `${window.location.pathname}${window.location.search}${window.location.hash}`

            if (window.location.pathname !== '/login') {
                // Check if not already on login page
                if (redirectUrl !== '/') {
                    history.navigate(`/login?${new URLSearchParams({ [QUERY_KEY_REDIRECT]: redirectUrl }).toString()}`)
                } else {
                    history.navigate('/login')
                }
            }
        },
    },
})

export const { setMeRawUser, updateMeUserSettings, updateFavorites, updateMeUser, signOut } = userSlice.actions
export const userReducer = userSlice.reducer
