const ItemPrice = {
    Buy: 'Buy',
    'A/B': 'Auction/Buy',
    Auc: 'Auction',
    'B/O': 'Buy/Offer',
} as const

export type ItemPriceType = typeof ItemPrice
export type ItemPriceKeys = keyof ItemPriceType
/** @public */
export type ItemPriceValues = ItemPriceType[ItemPriceKeys]

export default ItemPrice
