import CountryCode, { COUNTRY_UNKNOWN } from 'types/consts/countries/country-code.const'

/**
 * {@link https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.json}
 */
const CountryContinent = {
    [CountryCode.AF]: 'Asia',
    [CountryCode.AX]: 'Europe',
    [CountryCode.AL]: 'Europe',
    [CountryCode.DZ]: 'Africa',
    [CountryCode.AS]: 'Oceania',
    [CountryCode.AD]: 'Europe',
    [CountryCode.AO]: 'Africa',
    [CountryCode.AI]: 'Americas',
    [CountryCode.AQ]: '',
    [CountryCode.AG]: 'Americas',
    [CountryCode.AR]: 'Americas',
    [CountryCode.AM]: 'Asia',
    [CountryCode.AW]: 'Americas',
    [CountryCode.AU]: 'Oceania',
    [CountryCode.AT]: 'Europe',
    [CountryCode.AZ]: 'Asia',
    [CountryCode.BS]: 'Americas',
    [CountryCode.BH]: 'Asia',
    [CountryCode.BD]: 'Asia',
    [CountryCode.BB]: 'Americas',
    [CountryCode.BY]: 'Europe',
    [CountryCode.BE]: 'Europe',
    [CountryCode.BZ]: 'Americas',
    [CountryCode.BJ]: 'Africa',
    [CountryCode.BM]: 'Americas',
    [CountryCode.BT]: 'Asia',
    [CountryCode.BO]: 'Americas',
    [CountryCode.BQ]: 'Americas',
    [CountryCode.BA]: 'Europe',
    [CountryCode.BW]: 'Africa',
    [CountryCode.BV]: 'Americas',
    [CountryCode.BR]: 'Americas',
    [CountryCode.IO]: 'Africa',
    [CountryCode.BN]: 'Asia',
    [CountryCode.BG]: 'Europe',
    [CountryCode.BF]: 'Africa',
    [CountryCode.BI]: 'Africa',
    [CountryCode.CV]: 'Africa',
    [CountryCode.KH]: 'Asia',
    [CountryCode.CM]: 'Africa',
    [CountryCode.CA]: 'Americas',
    [CountryCode.KY]: 'Americas',
    [CountryCode.CF]: 'Africa',
    [CountryCode.TD]: 'Africa',
    [CountryCode.CL]: 'Americas',
    [CountryCode.CN]: 'Asia',
    [CountryCode.CX]: 'Oceania',
    [CountryCode.CC]: 'Oceania',
    [CountryCode.CO]: 'Americas',
    [CountryCode.KM]: 'Africa',
    [CountryCode.CG]: 'Africa',
    [CountryCode.CD]: 'Africa',
    [CountryCode.CK]: 'Oceania',
    [CountryCode.CR]: 'Americas',
    [CountryCode.CI]: 'Africa',
    [CountryCode.HR]: 'Europe',
    [CountryCode.CU]: 'Americas',
    [CountryCode.CW]: 'Americas',
    [CountryCode.CY]: 'Asia',
    [CountryCode.CZ]: 'Europe',
    [CountryCode.DK]: 'Europe',
    [CountryCode.DJ]: 'Africa',
    [CountryCode.DM]: 'Americas',
    [CountryCode.DO]: 'Americas',
    [CountryCode.EC]: 'Americas',
    [CountryCode.EG]: 'Africa',
    [CountryCode.SV]: 'Americas',
    [CountryCode.GQ]: 'Africa',
    [CountryCode.ER]: 'Africa',
    [CountryCode.EE]: 'Europe',
    [CountryCode.SZ]: 'Africa',
    [CountryCode.ET]: 'Africa',
    [CountryCode.FK]: 'Americas',
    [CountryCode.FO]: 'Europe',
    [CountryCode.FJ]: 'Oceania',
    [CountryCode.FI]: 'Europe',
    [CountryCode.FR]: 'Europe',
    [CountryCode.GF]: 'Americas',
    [CountryCode.PF]: 'Oceania',
    [CountryCode.TF]: 'Africa',
    [CountryCode.GA]: 'Africa',
    [CountryCode.GM]: 'Africa',
    [CountryCode.GE]: 'Asia',
    [CountryCode.DE]: 'Europe',
    [CountryCode.GH]: 'Africa',
    [CountryCode.GI]: 'Europe',
    [CountryCode.GR]: 'Europe',
    [CountryCode.GL]: 'Americas',
    [CountryCode.GD]: 'Americas',
    [CountryCode.GP]: 'Americas',
    [CountryCode.GU]: 'Oceania',
    [CountryCode.GT]: 'Americas',
    [CountryCode.GG]: 'Europe',
    [CountryCode.GN]: 'Africa',
    [CountryCode.GW]: 'Africa',
    [CountryCode.GY]: 'Americas',
    [CountryCode.HT]: 'Americas',
    [CountryCode.HM]: 'Oceania',
    [CountryCode.VA]: 'Europe',
    [CountryCode.HN]: 'Americas',
    [CountryCode.HK]: 'Asia',
    [CountryCode.HU]: 'Europe',
    [CountryCode.IS]: 'Europe',
    [CountryCode.IN]: 'Asia',
    [CountryCode.ID]: 'Asia',
    [CountryCode.IR]: 'Asia',
    [CountryCode.IQ]: 'Asia',
    [CountryCode.IE]: 'Europe',
    [CountryCode.IM]: 'Europe',
    [CountryCode.IL]: 'Asia',
    [CountryCode.IT]: 'Europe',
    [CountryCode.JM]: 'Americas',
    [CountryCode.JP]: 'Asia',
    [CountryCode.JE]: 'Europe',
    [CountryCode.JO]: 'Asia',
    [CountryCode.KZ]: 'Asia',
    [CountryCode.KE]: 'Africa',
    [CountryCode.KI]: 'Oceania',
    [CountryCode.KP]: 'Asia',
    [CountryCode.KR]: 'Asia',
    [CountryCode.KW]: 'Asia',
    [CountryCode.KG]: 'Asia',
    [CountryCode.LA]: 'Asia',
    [CountryCode.LV]: 'Europe',
    [CountryCode.LB]: 'Asia',
    [CountryCode.LS]: 'Africa',
    [CountryCode.LR]: 'Africa',
    [CountryCode.LY]: 'Africa',
    [CountryCode.LI]: 'Europe',
    [CountryCode.LT]: 'Europe',
    [CountryCode.LU]: 'Europe',
    [CountryCode.MO]: 'Asia',
    [CountryCode.MG]: 'Africa',
    [CountryCode.MW]: 'Africa',
    [CountryCode.MY]: 'Asia',
    [CountryCode.MV]: 'Asia',
    [CountryCode.ML]: 'Africa',
    [CountryCode.MT]: 'Europe',
    [CountryCode.MH]: 'Oceania',
    [CountryCode.MQ]: 'Americas',
    [CountryCode.MR]: 'Africa',
    [CountryCode.MU]: 'Africa',
    [CountryCode.YT]: 'Africa',
    [CountryCode.MX]: 'Americas',
    [CountryCode.FM]: 'Oceania',
    [CountryCode.MD]: 'Europe',
    [CountryCode.MC]: 'Europe',
    [CountryCode.MN]: 'Asia',
    [CountryCode.ME]: 'Europe',
    [CountryCode.MS]: 'Americas',
    [CountryCode.MA]: 'Africa',
    [CountryCode.MZ]: 'Africa',
    [CountryCode.MM]: 'Asia',
    [CountryCode.NA]: 'Africa',
    [CountryCode.NR]: 'Oceania',
    [CountryCode.NP]: 'Asia',
    [CountryCode.NL]: 'Europe',
    [CountryCode.NC]: 'Oceania',
    [CountryCode.NZ]: 'Oceania',
    [CountryCode.NI]: 'Americas',
    [CountryCode.NE]: 'Africa',
    [CountryCode.NG]: 'Africa',
    [CountryCode.NU]: 'Oceania',
    [CountryCode.NF]: 'Oceania',
    [CountryCode.MK]: 'Europe',
    [CountryCode.MP]: 'Oceania',
    [CountryCode.NO]: 'Europe',
    [CountryCode.OM]: 'Asia',
    [CountryCode.PK]: 'Asia',
    [CountryCode.PW]: 'Oceania',
    [CountryCode.PS]: 'Asia',
    [CountryCode.PA]: 'Americas',
    [CountryCode.PG]: 'Oceania',
    [CountryCode.PY]: 'Americas',
    [CountryCode.PE]: 'Americas',
    [CountryCode.PH]: 'Asia',
    [CountryCode.PN]: 'Oceania',
    [CountryCode.PL]: 'Europe',
    [CountryCode.PT]: 'Europe',
    [CountryCode.PR]: 'Americas',
    [CountryCode.QA]: 'Asia',
    [CountryCode.RE]: 'Africa',
    [CountryCode.RO]: 'Europe',
    [CountryCode.RU]: 'Europe',
    [CountryCode.RW]: 'Africa',
    [CountryCode.BL]: 'Americas',
    [CountryCode.SH]: 'Africa',
    [CountryCode.KN]: 'Americas',
    [CountryCode.LC]: 'Americas',
    [CountryCode.MF]: 'Americas',
    [CountryCode.PM]: 'Americas',
    [CountryCode.VC]: 'Americas',
    [CountryCode.WS]: 'Oceania',
    [CountryCode.SM]: 'Europe',
    [CountryCode.ST]: 'Africa',
    [CountryCode.SA]: 'Asia',
    [CountryCode.SN]: 'Africa',
    [CountryCode.RS]: 'Europe',
    [CountryCode.SC]: 'Africa',
    [CountryCode.SL]: 'Africa',
    [CountryCode.SG]: 'Asia',
    [CountryCode.SX]: 'Americas',
    [CountryCode.SK]: 'Europe',
    [CountryCode.SI]: 'Europe',
    [CountryCode.SB]: 'Oceania',
    [CountryCode.SO]: 'Africa',
    [CountryCode.ZA]: 'Africa',
    [CountryCode.GS]: 'Americas',
    [CountryCode.SS]: 'Africa',
    [CountryCode.ES]: 'Europe',
    [CountryCode.LK]: 'Asia',
    [CountryCode.SD]: 'Africa',
    [CountryCode.SR]: 'Americas',
    [CountryCode.SJ]: 'Europe',
    [CountryCode.SE]: 'Europe',
    [CountryCode.CH]: 'Europe',
    [CountryCode.SY]: 'Asia',
    [CountryCode.TW]: 'Asia',
    [CountryCode.TJ]: 'Asia',
    [CountryCode.TZ]: 'Africa',
    [CountryCode.TH]: 'Asia',
    [CountryCode.TL]: 'Asia',
    [CountryCode.TG]: 'Africa',
    [CountryCode.TK]: 'Oceania',
    [CountryCode.TO]: 'Oceania',
    [CountryCode.TT]: 'Americas',
    [CountryCode.TN]: 'Africa',
    [CountryCode.TR]: 'Asia',
    [CountryCode.TM]: 'Asia',
    [CountryCode.TC]: 'Americas',
    [CountryCode.TV]: 'Oceania',
    [CountryCode.UG]: 'Africa',
    [CountryCode.UA]: 'Europe',
    [CountryCode.AE]: 'Asia',
    [CountryCode.GB]: 'Europe',
    [CountryCode.US]: 'Americas',
    [CountryCode.UM]: 'Oceania',
    [CountryCode.UY]: 'Americas',
    [CountryCode.UZ]: 'Asia',
    [CountryCode.VU]: 'Oceania',
    [CountryCode.VE]: 'Americas',
    [CountryCode.VN]: 'Asia',
    [CountryCode.VG]: 'Americas',
    [CountryCode.VI]: 'Americas',
    [CountryCode.WF]: 'Oceania',
    [CountryCode.EH]: 'Africa',
    [CountryCode.YE]: 'Asia',
    [CountryCode.ZM]: 'Africa',
    [CountryCode.ZW]: 'Africa',
    [COUNTRY_UNKNOWN]: '',
} as const

export type CountryContinentType = typeof CountryContinent
export type CountryContinentKeys = keyof CountryContinentType
/** @public */
export type CountryContinentValues = CountryContinentType[CountryContinentKeys]

export default CountryContinent
