import { SEARCH_TEXT, SEARCH_IS_NEW, SEARCH_IS_NEW_PRICE, SEARCH_IS_FAVORITE } from 'types/others'
import type { ScraperAll } from 'types/types'

/**
 * GetSearchQuery
 * @param scraper scraper
 */
export default function getSearchQuery(scraper?: ScraperAll) {
    const query = new URLSearchParams()
    if (scraper?.filtersPre?.text) {
        query.set(SEARCH_TEXT, scraper?.filtersPre?.text ?? '')
    }
    if (scraper?.filtersPre?.isNew) {
        query.set(SEARCH_IS_NEW, (scraper?.filtersPre?.isNew ?? false).toString())
    }
    if (scraper?.filtersPre?.isNewPrice) {
        query.set(SEARCH_IS_NEW_PRICE, (scraper?.filtersPre?.isNewPrice ?? false).toString())
    }
    if (scraper?.filtersPre?.isFavorite) {
        query.set(SEARCH_IS_FAVORITE, (scraper?.filtersPre?.isFavorite ?? false).toString())
    }
    return query.toString()
}
