import type { NavigateFunction } from 'react-router-dom'

/**
 * Unsafely access history outside Router Context
 * {@link https://github.com/remix-run/react-router/issues/8481#issuecomment-996578067}
 */
const history: {
    /** Navigate */
    navigate: NavigateFunction
} = {
    navigate: () => null,
}

export default history
