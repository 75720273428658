import createClient, { type Middleware } from 'openapi-fetch'
import { generateException } from 'requests/exception'
import type { paths } from 'requests/types/specs'

const client = createClient<paths>()

const throwMiddleware: Middleware = {
    async onResponse({ response }) {
        // https://openapi-ts.dev/openapi-fetch/middleware-auth#throwing
        if (!response.ok) {
            throw generateException(await response.json())
        }
    },
}

const refreshMiddleware: Middleware = {
    async onResponse({ response, request }) {
        if (response.status === 401 && !request.url?.includes('users/authentication/refresh')) {
            /**
             * Note: this is not in ReactQuery as there no result returns
             * {@link https://github.com/TanStack/query/discussions/4457}
             */
            await client.PATCH('/api/users/authentication/refresh')
            return fetch(request.url, request)
        }

        return response
    },
}

client.use(throwMiddleware, refreshMiddleware)

export default client
