import { useMemo } from 'react'
import { useDispatch } from 'store'
import { setNavigation, setAlert } from 'store/slices/navigation.slice'
import { setLastSearchOn, setItemsFound, setIsQuickviewOpen, setAreDisableScraperVisible } from 'store/slices/search.slice'
import { setIsEditing, setAreDisableSearchVisible } from 'store/slices/home.slice'
import { setInformations, setIsUpdating } from 'store/slices/pwa.slice'
import { setMeRawUser, updateFavorites, updateMeUser, signOut, updateMeUserSettings } from 'store/slices/user.slice'

/**
 * UseDispatches
 */
export default function useDispatches() {
    const dispatch = useDispatch()

    return useMemo(
        () => ({
            navigation: {
                setNavigation: (param: Parameters<typeof setNavigation>[0]) => dispatch(setNavigation(param)),
                setAlert: (param: Parameters<typeof setAlert>[0]) => dispatch(setAlert(param)),
            },
            home: {
                setIsEditing: (param: Parameters<typeof setIsEditing>[0]) => dispatch(setIsEditing(param)),
                setAreDisableSearchVisible: (param: Parameters<typeof setAreDisableSearchVisible>[0]) =>
                    dispatch(setAreDisableSearchVisible(param)),
            },
            search: {
                setLastSearchOn: (param: Parameters<typeof setLastSearchOn>[0]) => dispatch(setLastSearchOn(param)),
                setItemsFound: (param: Parameters<typeof setItemsFound>[0]) => dispatch(setItemsFound(param)),
                setIsQuickviewOpen: (param: Parameters<typeof setIsQuickviewOpen>[0]) => dispatch(setIsQuickviewOpen(param)),
                setAreDisableScraperVisible: (param: Parameters<typeof setAreDisableScraperVisible>[0]) =>
                    dispatch(setAreDisableScraperVisible(param)),
            },
            user: {
                setMeRawUser: (param: Parameters<typeof setMeRawUser>[0]) => dispatch(setMeRawUser(param)),
                updateMeUserSettings: (param: Parameters<typeof updateMeUserSettings>[0]) => dispatch(updateMeUserSettings(param)),
                updateFavorites: (param: Parameters<typeof updateFavorites>[0]) => dispatch(updateFavorites(param)),
                updateMeUser: (param: Parameters<typeof updateMeUser>[0]) => dispatch(updateMeUser(param)),
                signOut: (param: Parameters<typeof signOut>[0]) => dispatch(signOut(param)),
            },
            pwa: {
                setInformations: (param: Parameters<typeof setInformations>[0]) => dispatch(setInformations(param)),
                setIsUpdating: (param: Parameters<typeof setIsUpdating>[0]) => dispatch(setIsUpdating(param)),
            },
        }),
        [dispatch],
    )
}
