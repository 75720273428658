/**
 * {@link https://www.ecb.europa.eu/stats/eurofxref/eurofxref-daily.xml
 */
const CurrencyCode = {
    AUD: 'AUD',
    BGN: 'BGN',
    BRL: 'BRL',
    CAD: 'CAD',
    CHF: 'CHF',
    CNY: 'CNY',
    CZK: 'CZK',
    DKK: 'DKK',
    EUR: 'EUR',
    GBP: 'GBP',
    HKD: 'HKD',
    HRK: 'HRK',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    ISK: 'ISK',
    JPY: 'JPY',
    KRW: 'KRW',
    MXN: 'MXN',
    MYR: 'MYR',
    NOK: 'NOK',
    NZD: 'NZD',
    PHP: 'PHP',
    PLN: 'PLN',
    RON: 'RON',
    RUB: 'RUB',
    SEK: 'SEK',
    SGD: 'SGD',
    THB: 'THB',
    TRY: 'TRY',
    USD: 'USD',
    ZAR: 'ZAR',
} as const

export type CurrencyCodeType = typeof CurrencyCode
export type CurrencyCodeKeys = keyof CurrencyCodeType
/** @public */
export type CurrencyCodeValues = CurrencyCodeType[CurrencyCodeKeys]

export default CurrencyCode
