/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import history from 'utils/others/history'
import getUuid from 'utils/others/get-uuid'
import type { PayloadAction, Middleware } from '@reduxjs/toolkit'

/**
 * Search middleware, must have "getDefaultMiddleware"
 */
export const searchMiddleware: Array<Middleware> = []

/**
 * Items found from API to display in layout
 */
export interface ItemsFoundType {
    /** Total */
    total: number | null
    /** Current */
    current: number | null
}

export interface SearchState {
    /** Last search on */
    lastSearchOn: Date | null
    /** Number of items found */
    itemsFound: ItemsFoundType
    /** Is quickview menu swiping */
    isQuickviewOpen: boolean
    /** Are disable scraper visible  */
    areDisableScraperVisible: boolean
    /** Page key suffix to reset render */
    pageKey: string
}

/**
 * Search Slice
 */
const searchSlice = createSlice({
    name: 'search',
    initialState: {
        lastSearchOn: null,
        itemsFound: { current: null, total: null },
        isQuickviewOpen: false,
        areDisableScraperVisible: false,
        pageKey: getUuid(),
    } as SearchState,
    reducers: {
        /**
         * SetLastSearchOn
         * @param state state
         * @param action action
         */
        setLastSearchOn: (state, action: PayloadAction<Date | null>) => {
            state.lastSearchOn = action.payload
        },
        /**
         * SetItemsFound
         * @param state state
         * @param action action
         */
        setItemsFound: (state, action: PayloadAction<ItemsFoundType>) => {
            if (state.itemsFound !== action.payload) {
                state.itemsFound = action.payload
            }
        },
        /**
         * SetIsQuickviewOpen
         * @param state state
         * @param action action
         */
        setIsQuickviewOpen: (state, action: PayloadAction<boolean>) => {
            if (state.isQuickviewOpen !== action.payload) {
                state.isQuickviewOpen = action.payload
            }
        },
        /**
         * SetAreDisableScraperVisible
         * @param state state
         * @param action action
         */
        setAreDisableScraperVisible: (state, action: PayloadAction<boolean>) => {
            if (state.areDisableScraperVisible !== action.payload) {
                state.areDisableScraperVisible = action.payload
                state.pageKey = getUuid()
                history.navigate({ hash: '', search: '' }, { replace: true, preventScrollReset: true })
                state.lastSearchOn = null
                state.itemsFound = { total: null, current: null }
            }
        },
    },
})

export const { setLastSearchOn, setItemsFound, setIsQuickviewOpen, setAreDisableScraperVisible } = searchSlice.actions
export const searchReducer = searchSlice.reducer
