import { NotificationPeriod } from 'types/consts'
import type { NotificationPeriodValues } from 'types/consts/notification-period.const'

const NotificationPeriodsData: {
    [key in NotificationPeriodValues]: {
        /** Name */
        name: string
    }
} = {
    [NotificationPeriod.NORMAL]: {
        name: 'Normal (every 15 minutes)',
    },
    [NotificationPeriod.SLOW]: {
        name: 'Slow (every 1 hour)',
    },
    [NotificationPeriod.VERY_SLOW]: {
        name: 'Very slow (every 4 hours)',
    },
} as const

export default NotificationPeriodsData
