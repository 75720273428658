import client from 'requests/client'
import type { MutationOptions, Parameters, QueryOptions } from 'requests/types/common'

/**
 * Search queries
 */
export const searchesQueries = {
    upsert: () =>
        ({
            mutationFn: async ({ body, signal, params }) =>
                (
                    await (params?.path?.searchId
                        ? client.PUT('/api/searches/{searchId}', { params, body, signal })
                        : client.POST('/api/searches', { body, signal }))
                ).data,
        }) as MutationOptions<'PUT', '/api/searches/{searchId}'>,
    remove: () =>
        ({
            mutationFn: async ({ params }) => (await client.DELETE('/api/searches/{searchId}', { params })).data,
        }) as MutationOptions<'DELETE', '/api/searches/{searchId}'>,
    sort: () =>
        ({
            mutationFn: async ({ body, signal }) => (await client.PATCH('/api/searches/search-sort', { body, signal })).data,
        }) as MutationOptions<'PATCH', '/api/searches/search-sort'>,
}

/**
 * Scrapers queries
 */
export const scrapersQueries = {
    update: () =>
        ({
            mutationFn: async ({ body, signal, params }) =>
                (await client.PUT('/api/searches/{searchId}/scrapers/{scraperValue}', { body, signal, params })).data,
        }) as MutationOptions<'PUT', '/api/searches/{searchId}/scrapers/{scraperValue}'>,
}

/**
 * Items queries
 */
export const itemsQueries = {
    getAll: ({ searchId, scraperValue }: Parameters<'get', '/api/searches/{searchId}/scrapers/{scraperValue}/items'>['params']['path']) =>
        ({
            queryKey: ['searches', searchId, 'scrapers', scraperValue, 'items'],
            queryFn: async ({ signal }) =>
                (
                    await client.GET('/api/searches/{searchId}/scrapers/{scraperValue}/items', {
                        params: { path: { scraperValue, searchId } },
                        signal,
                    })
                ).data,
        }) as QueryOptions<'GET', '/api/searches/{searchId}/scrapers/{scraperValue}/items'>,
    updateHistory: () =>
        ({
            mutationFn: async ({ body, signal, params }) =>
                (
                    await client.PATCH('/api/searches/{searchId}/scrapers/{scraperValue}/histories', {
                        params,
                        body,
                        signal,
                    })
                ).data,
        }) as MutationOptions<'PATCH', '/api/searches/{searchId}/scrapers/{scraperValue}/histories'>,
    addFavorites: () =>
        ({
            mutationFn: async ({ body, signal, params }) =>
                (
                    await client.PATCH('/api/searches/{searchId}/scrapers/{scraperValue}/favorites', {
                        params,
                        body,
                        signal,
                    })
                ).data!,
        }) as MutationOptions<'PATCH', '/api/searches/{searchId}/scrapers/{scraperValue}/favorites'>,
    removeFavorites: () =>
        ({
            mutationFn: async ({ body, signal, params }) =>
                (
                    await client.DELETE('/api/searches/{searchId}/scrapers/{scraperValue}/favorites', {
                        params,
                        body,
                        signal,
                    })
                ).data!,
        }) as MutationOptions<'DELETE', '/api/searches/{searchId}/scrapers/{scraperValue}/favorites'>,
}

/**
 * Users queries
 */
export const usersQueries = {
    updateMe: () =>
        ({
            mutationFn: async ({ body, signal }) => (await client.PATCH('/api/users/me', { body, signal })).data,
        }) as MutationOptions<'PATCH', '/api/users/me'>,
    getMe: () =>
        ({
            queryKey: ['users', 'me'],
            queryFn: async ({ signal }) => (await client.GET('/api/users/me', { signal })).data,
        }) as QueryOptions<'GET', '/api/users/me'>,
    getMeLastUpdate: () =>
        ({
            queryKey: ['users', 'me', 'lastupdate'],
            queryFn: async ({ signal }) => (await client.GET('/api/users/me/lastupdate', { signal })).data,
        }) as QueryOptions<'GET', '/api/users/me/lastupdate'>,
    login: () =>
        ({
            mutationFn: async ({ body, signal }) => (await client.POST('/api/users/authentication/login', { body, signal })).data,
        }) as MutationOptions<'POST', '/api/users/authentication/login'>,
    logout: () =>
        ({
            mutationFn: async ({ body, signal }) => (await client.POST('/api/users/authentication/logout', { body, signal })).data,
        }) as MutationOptions<'POST', '/api/users/authentication/logout'>,
    refresh: () =>
        ({
            mutationFn: async ({ body, signal }) => (await client.PATCH('/api/users/authentication/refresh', { body, signal })).data,
        }) as MutationOptions<'PATCH', '/api/users/authentication/refresh'>,
}

/**
 * Users queries
 */
export const webAuthnQueries = {
    getRegisterOption: () =>
        ({
            queryKey: ['webauthns', 'register', 'options'],
            queryFn: async ({ signal }) => (await client.GET('/api/webauthns/register/options', { signal })).data,
        }) as QueryOptions<'GET', '/api/webauthns/register/options'>,
    register: () =>
        ({
            mutationFn: async ({ body, signal }) => (await client.POST('/api/webauthns/register', { body, signal })).data,
        }) as MutationOptions<'POST', '/api/webauthns/register'>,
    getAuthenticateOptions: () =>
        ({
            queryKey: ['webauthns', 'authenticate', 'options'],
            queryFn: async ({ signal }) => (await client.GET('/api/webauthns/authenticate/options', { signal })).data,
        }) as QueryOptions<'GET', '/api/webauthns/authenticate/options'>,
    authenticate: () =>
        ({
            mutationFn: async ({ body, signal }) => (await client.POST('/api/webauthns/authenticate', { body, signal })).data,
        }) as MutationOptions<'POST', '/api/webauthns/authenticate'>,
}
