import { CurrencyCode } from 'types/consts'
import env from 'types/env'

/**
 * Get Price Displayable
 * @param str str
 * @returns string
 */
export default function getPriceDisplayable(str: string) {
    // Check if price follow the convention
    if (!/^-?([0-9]*\.[0-9]{2})\s[A-Z]{3}$/g.test(str)) {
        return str
    }

    const [price, currency] = str.split(' ') as [string, string]

    const priceFormatted = parseFloat(price)?.toLocaleString(env.LOCALE, {
        minimumFractionDigits: currency === CurrencyCode.JPY ? 0 : 2,
    })

    return `${priceFormatted} ${currency}`
}
