/**
 * {@link https://davidwalsh.name/get-promise-status}
 */

/**
 * Uses setTimeout with Promise to create an arbitrary delay time. In these examples,
 * a 0 millisecond delay is an instantly resolving promise that we can jude status against
 * @param milliseconds Milliseconds
 * @param returnValue ReturnValue
 */
async function delay(): Promise<boolean> {
    return new Promise(done => {
        setTimeout(() => done(false), 0)
    })
}

// Promise.race in all of these functions uses delay of 0 to instantly resolve.
// If the promise is resolved or rejected, returning that value will beat the setTimeout in the race

/**
 * IsResolved
 * @param promise Promise
 */
export async function isResolved(promise: Promise<unknown>): Promise<boolean> {
    return Promise.race([
        delay(),
        promise.then(
            () => true,
            () => false,
        ),
    ])
}

/**
 * IsRejected
 * @public
 * @param promise Promise
 */
export async function isRejected(promise: Promise<unknown>): Promise<boolean> {
    return Promise.race([
        delay(),
        promise.then(
            () => false,
            () => true,
        ),
    ])
}

/**
 * IsSettled
 * @public
 * @param promise Promise
 */
export async function isSettled(promise: Promise<unknown>): Promise<boolean> {
    return Promise.race([
        delay(),
        promise.then(
            () => true,
            () => true,
        ),
    ])
}
