/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction, Middleware } from '@reduxjs/toolkit'

/**
 * Home middleware, must have "getDefaultMiddleware"
 */
export const homeMiddleware: Array<Middleware> = []

export interface HomeState {
    /** Is editing  page */
    isEditing: boolean
    /** Are disable search visible  */
    areDisableSearchVisible: boolean
}

/**
 * Home Slice
 */
const homeSlice = createSlice({
    name: 'home',
    initialState: {
        isEditing: false,
        areDisableSearchVisible: false,
    } as HomeState,
    reducers: {
        /**
         * SetIsEditing
         * @param state state
         * @param action action
         */
        setIsEditing: (state, action: PayloadAction<boolean>) => {
            if (state.isEditing !== action.payload) {
                state.isEditing = action.payload
            }
        },
        /**
         * SetAreDisableSearchVisible
         * @param state state
         * @param action action
         */
        setAreDisableSearchVisible: (state, action: PayloadAction<boolean>) => {
            if (state.areDisableSearchVisible !== action.payload) {
                state.areDisableSearchVisible = action.payload
            }
        },
    },
})

export const { setIsEditing, setAreDisableSearchVisible } = homeSlice.actions
export const homeReducer = homeSlice.reducer
